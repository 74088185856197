<template>
  <el-table :data="sourForm.sour_mtrl_list" border @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="48" align="center">
      <template slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">供应商</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.supp_name'">
          <el-select
            v-if="sourForm.sour_mtrl_list[scope.$index].addFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].supp_id"
            placeholder="请选择物料类型"
            clearable
          >
            <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
          </el-select>
          <el-input
            v-else
            :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].supp_name"
            placeholder="请填写供应商"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料编号</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_no'" :rules="[{ required: true }]">
          <el-input
            @change="mtrlNoChange(scope)"
            :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_no"
            maxlength="10"
            show-word-limit
            placeholder="暂无物料编号"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料名称</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_name'" :rules="[{ required: true }]">
          <el-tooltip
            class="item"
            effect="light"
            :content="sourForm.sour_mtrl_list[scope.$index].mtrl_name ? sourForm.sour_mtrl_list[scope.$index].mtrl_name + '' : ''"
            placement="top-start"
          >
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_name"
              maxlength="30"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料类型</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_type'" :rules="[{ required: true }]">
          <div v-if="!sourForm.sour_mtrl_list[scope.$index].addFlag">
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              v-if="sourForm.sour_mtrl_list[scope.$index].mtrl_type === 0"
              v-model="mtrlType1"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              v-else-if="sourForm.sour_mtrl_list[scope.$index].mtrl_type === 1"
              v-model="mtrlType2"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              v-else-if="sourForm.sour_mtrl_list[scope.$index].mtrl_type === 2"
              v-model="mtrlType3"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              v-else
              v-model="mtrlType4"
              show-word-limit
              placeholder="暂无物料名称"
            ></el-input>
          </div>
          <!-- <el-select v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_type" placeholder="请选择物料类型" v-else>
            <el-option
              v-for="item in mtrlType"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="规格" show-word-limit>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_spec'">
          <el-tooltip
            class="item"
            effect="light"
            :content="sourForm.sour_mtrl_list[scope.$index].mtrl_spec ? sourForm.sour_mtrl_list[scope.$index].mtrl_spec + '' : ''"
            placement="top-start"
          >
            <el-input
              :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
              maxlength="50"
              v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_spec"
              placeholder="暂无规格"
            ></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span>潘通色号</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_color'">
          <el-input
            :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_color"
            maxlength="20"
            show-word-limit
            placeholder="暂无颜色"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">门幅</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_width'">
          <el-input
            :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
            show-word-limit
            maxlength="8"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_width"
            @blur="sourForm.sour_mtrl_list[scope.$index].mtrl_width = helper.haveFour(sourForm.sour_mtrl_list[scope.$index].mtrl_width)"
            @input="sourForm.sour_mtrl_list[scope.$index].mtrl_width = helper.keepTNum1(sourForm.sour_mtrl_list[scope.$index].mtrl_width)"
            placeholder="暂无门幅"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">单位</span>
      </template>
      <template slot-scope="scope">
        <el-form-item label-width="1000" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_unit'" :rules="[{ required: true }]">
          <el-select
            v-if="sourForm.sour_mtrl_list[scope.$index].addFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_unit"
            placeholder="请选择物料单位"
          >
            <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
          </el-select>
          <el-input
            v-else
            :disabled="sourForm.sour_mtrl_list[scope.$index].disabledFlag"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_unit"
            placeholder="请填写单位"
            clearable
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <!-- <el-table-column label="单价">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.'+scope.$index+'.mtrl_price'">
          <el-input  :disabled="true" show-word-limit maxlength="6" v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_price" placeholder="暂无单价"></el-input>
        </el-form-item>
      </template>
    </el-table-column> -->
    <el-table-column label="物料生产价">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_prod_price'">
          <el-input
            disabled
            show-word-limit
            maxlength="12"
            @change="priceChange(scope)"
            @blur="
              sourForm.sour_mtrl_list[scope.$index].mtrl_prod_price = helper.calcPrice(
                sourForm.sour_mtrl_list[scope.$index].mtrl_prod_price,
                4,
                10000000
              )
            "
            @input="
              sourForm.sour_mtrl_list[scope.$index].mtrl_prod_price = helper.keepTNum1(
                sourForm.sour_mtrl_list[scope.$index].mtrl_prod_price
              )
            "
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_prod_price"
            placeholder="暂无物料生产价"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="物料零售价">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'sour_mtrl_list.' + scope.$index + '.no_mdrl_price'">
          <el-input
            @change="priceChange(scope)"
            @blur="
              sourForm.sour_mtrl_list[scope.$index].mtrl_retail_price = helper.calcPrice(
                sourForm.sour_mtrl_list[scope.$index].mtrl_retail_price,
                4,
                10000000
              )
            "
            show-word-limit
            maxlength="12"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_retail_price"
            @input="
              sourForm.sour_mtrl_list[scope.$index].mtrl_retail_price = helper.keepTNum1(
                sourForm.sour_mtrl_list[scope.$index].mtrl_retail_price
              )
            "
            placeholder="暂无物料零售价"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料数量</span>
      </template>
      <template slot-scope="scope">
        <el-form-item
          label-width="0"
          :prop="'sour_mtrl_list.' + scope.$index + '.mtrl_num'"
          :rules="[{ required: true, trigger: 'change' }]"
        >
          <el-input
            v-if="flag"
            show-word-limit
            maxlength="9"
            @change="priceChange(scope, 1)"
            v-model="sourForm.sour_mtrl_list[scope.$index].mtrl_num"
            @input="sourForm.sour_mtrl_list[scope.$index].mtrl_num = keepTNum1(sourForm.sour_mtrl_list[scope.$index].mtrl_num)"
            placeholder="暂无物料数量"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, post } from '@api/request';
import { sourAPI } from '@api/modules/sour';
import { suppAPI } from '@api/modules/supp';

export default {
  name: 'SmplEditBomChild',
  props: {
    sourForm: {
      type: Object,
      required: true
    },
    mtrlTypeGroupOpt: {
      type: Array,
      required: true
    },
    stffType: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrlType1: '原面料',
      mtrlType2: '辅料',
      mtrlType3: '包材',
      mtrlType4: '',
      flag: true,
      suppType: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getSuppsList();
    },
    keepTNum1(number) {
      this.$forceUpdate();
      return number.replace(/[^0-9.]/g, '');
    },
    priceChange1(scope) {
      let price = 0.0;
      let mtrlList = this.sourForm.sour_mtrl_list;
      for (let i = 0; i < mtrlList.length; i++) {
        if (mtrlList[i].mtrl_num) {
          price +=
            (parseInt(Number(mtrlList[i].mtrl_retail_price) * 10000) * parseInt(Number(mtrlList[i].mtrl_num) * 10000)) / 10000 / 10000;
        }
      }
      this.$emit('sourPrice', price);
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 更改数量修改价格
    priceChange(scope, type) {
      let price = 0.0;
      let mtrlList = this.sourForm.sour_mtrl_list;
      for (let i = 0; i < mtrlList.length; i++) {
        if (mtrlList[i].mtrl_num && mtrlList[i].mtrl_retail_price) {
          price +=
            (parseInt(Number(mtrlList[i].mtrl_retail_price) * 10000) * parseInt(Number(mtrlList[i].mtrl_num) * 10000)) / 10000 / 10000;
        }
      }
      // price = this.haveFour(price);
      // if(price<1000000){
      this.$emit('sourPrice', price);
      // }
      if (type === 1) {
        let val = scope.row.mtrl_num;
        if (val) {
          let temp = val.toString();
          let ind = temp.indexOf('.');
          if (Number(temp) < 10000) {
            if (ind < 0) {
              scope.row.mtrl_num = val + '.0000';
            } else {
              if (temp.length - 1 - ind > 4) {
                scope.row.mtrl_num = temp.slice(0, ind + 5);
              } else if (temp.length - 1 - ind < 4) {
                let index = 4 - (temp.length - 1 - ind);
                for (let i = 0; i < index; i++) {
                  temp = temp + '0';
                }
                scope.row.mtrl_num = temp;
              } else {
                scope.row.mtrl_num = temp;
              }
            }
          } else {
            this.$message({ message: '物料数量不能大于9999.9999', type: 'error' });
            scope.row.mtrl_num = '';
          }
        } else {
          scope.row.mtrl_num = '';
        }
      }
    },
    //bom清单选择框
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange', this.selectionsList);
    },
    // 物料编号重复提醒
    mtrlNoChange(val) {
      console.log('val', val);
      if (val.row.mtrl_no) {
        get(sourAPI.judgeSourMtrlNo, { mtrl_no: val.row.mtrl_no })
          .then(res => {
            if (res.data.code === 0) {
              console.log('成功');
            } else if (res.data.code === 7) {
              val.row.mtrl_no = null;
              this.$message({
                type: 'error',
                message: '物料编号重复，请重新填写'
              });
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 数量保留四位
    haveFour(e) {
      // this.flag = false
      // console.log('e',e);
      // if(e === undefined){
      //   return '0.0000'
      // }
      // this.$nextTick(()=>{
      //   this.flag = true
      // })
      // if(val){
      //   let temp = val.toString();
      //   let ind = temp.indexOf('.');
      //   if(Number(temp)<10000){
      //     if(ind<0){
      //         return temp+'.0000'
      //     }else{
      //       if(temp.length-1-ind>4){
      //         return temp.slice(0,ind+5)
      //       }else if(temp.length-1-ind<4){
      //         let index = 4-(temp.length-1-ind);
      //         for(let i=0;i<index;i++){
      //           temp = temp + '0'
      //         }
      //         return temp
      //       }else{
      //         return temp
      //       }
      //     }
      //   }else{
      //     this.$message({message:'物料数量不能大于9999.9999',type:'error'});
      //         return ''
      //   }
      // }else{
      //   return ''
      // }
    }
  }
};
</script>

<style scoped></style>
